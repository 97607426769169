import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { PacienteService } from 'src/app/core/services/http/paciente.service';

@Component({
  selector: 'app-modal-ver-mas',
  templateUrl: './modal-ver-mas.component.html',
  styleUrls: ['./modal-ver-mas.component.scss']
})
export class ModalVerMasComponent implements OnInit {
  zonasCuerpo: any[] = [];
  paciente;

  constructor(private modalService: ModalService, private PacienteService:PacienteService) { }

  ngOnInit(): void {
    this.paciente = localStorage.getItem("dataAtencion");
    this.paciente = JSON.parse(this.paciente);
    localStorage.setItem("modalurgencia", "false");
    this.onSubmit();
  }

  async onSubmit() {
    var local = parseInt(localStorage.getItem("idAtencion"))
    var resp = await this.PacienteService.partesCuerpo(local);
    this.zonasCuerpo = resp["dataZona"]
  }


  async close() {
    localStorage.setItem("modalurgencia","false");
    localStorage.removeItem("idAtencion");
    this.modalService.closeModal();
  }
}
