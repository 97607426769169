import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from 'src/app/modules/login/login.component';
import {ListPacienteComponent} from 'src/app/modules/enfermera/list-paciente/list-paciente.component';
import {AuthGuard} from 'src/app/guards/auth.guard';

const routes: Routes = [
  { path: 'personal', component: ListPacienteComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
