import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { UserIdleModule } from 'angular-user-idle';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppComponent } from './app.component';
import { KeyboardComponent } from './custom/keyboard/keyboard.component';
import {LoginComponent} from 'src/app/modules/login/login.component';
import {ListPacienteComponent} from 'src/app/modules/enfermera/list-paciente/list-paciente.component';
import {ModalVerMasComponent} from 'src/app/modules/enfermera/modal-ver-mas/modal-ver-mas.component';
import {HeaderComponent} from 'src/app/modules/shared/header/header.component';
import {ModalDerivarComponent} from 'src/app/modules/shared/modal-derivar/modal-derivar.component';
import {ModalUrgenciaComponent} from 'src/app/modules/shared/modal-urgencia/modal-urgencia.component';
import { ToastrModule  } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPopper } from 'angular-popper';
import {ToastModule} from 'primeng/toast';
import { CommonModule } from '@angular/common';
import {AccordionModule} from 'primeng/accordion'; 
import {MenuItem} from 'primeng/api';   
/////////////////////////////////////////
import { ChartsModule } from 'ng2-charts';
import {DataTablesModule} from 'angular-datatables';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import {ChartModule} from 'primeng/chart';
////////////////////////////
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { VonConfirmationModule } from '@von-development-studio/primeng-confirmation-dialog';
@NgModule({
  declarations: [
    AppComponent,
    KeyboardComponent,
    ListPacienteComponent,
    ModalVerMasComponent,
    HeaderComponent,
    ModalUrgenciaComponent,
    ModalDerivarComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ToastModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      progressBar: true
    }),
    FormsModule,
    UserIdleModule.forRoot({ idle: 240, timeout: 60 }),
    ReactiveFormsModule,
    ModalModule.forRoot(),
    FormsModule,
    ChartsModule,
    DataTablesModule,
    TableModule,
    BrowserAnimationsModule,
    ButtonModule,
    VonConfirmationModule,
    RippleModule,
    ChartModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    RadioButtonModule,
    InputNumberModule,
    InputTextareaModule,
    ConfirmDialogModule,
    AccordionModule
  ],
  entryComponents: [
    KeyboardComponent
  ],
  providers: [MessageService, ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
