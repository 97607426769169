<!-- <div class="modal-header">

    <button type="button" class="close pull-right" (click)="close()">
    <span><i class="fas fa-times-circle"></i></span>
  </button>
</div> -->
<div class="modal-body">
    <button type="button mt-n5" class="close pull-right" (click)="close()">
    <span><i class="fas fa-times-circle"></i></span>
  </button>
    <div class="row text-center">
        <div class="col-12 mb-3">
            <h4 class="modal-title pull-center text-bluelight">Nombre paciente</h4>
        </div>
        <div class="col-12 mb-3">
            <select class="form-control" [formControl]="doc">
        <option value="" disabled>Seleccionar Doctor</option>
        <option *ngFor="let doc of doctors, index as index" [ngValue]="doc">
          <div (click)="doctorselec(index)">{{doc.nombre}}</div>
        </option>
      </select>
        </div>
        <div class="col-12 text-center">
            <button class="btn-login mt-2" (click)="onSubmit()" [disabled]="doc.invalid">DERIVAR</button>
        </div>
    </div>
</div>