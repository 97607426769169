<video src="assets/media/inicio.mp4" muted autoplay loop class="video-login"></video>

<div class="row">
    <div class="col-md-7 offset-md-5 col-12 text-center">
        <div class="logo mt-5">
            <img src="assets/img/logo.svg" class="w-25 pt-5 mb-5">
        </div>
        <h1 class="text-grey">Autoatención Salud</h1>
        <h1 class="text-bluelight display-4" [innerHTML]="title"></h1>

        <div class="row">
            <div class="col-md-10 offset-md-1 col-12">
                <input class="input-login ml-5" type="text" placeholder="USUARIO" [formControl]="user" maxlength="12" [class.is-invalid]="user.value.length > 10 && !rutValid" (keyup)="validaRut()">
                <div *ngIf="user.value.length > 9 && !this.rutValid" class="invalid-feedback">
                    <i class="far fa-times-circle"></i> Por favor, ingrese un rut válido
                </div>
            </div>

            <div class="col-md-10 offset-md-1 col-12">
                <input class="input-login ml-5" type="password" placeholder="PASSWORD" [formControl]="pass" [class.is-invalid]="user.value.length > 10 && !rutValid">
            </div>

            <div class="col-md-10 offset-md-1 col-12">
                <button class="btn-login ml-5 mt-2" (click)="onSubmit(user.value, pass.value)" [disabled]="pass.value.length < 3"*ngIf="!loading" [ngClass]="{'deshabilitado':user.value.length==0 || pass.value.length==0}">INGRESAR</button>
                <button class="btn-login ml-5 mt-2" disabled *ngIf="loading">
          <span class="spinner-border spinner-border-sm"></span> Cargando...
        </button>
            </div>
        </div>
    </div>
</div>