import { Component, OnInit } from '@angular/core';
import { PacienteService } from 'src/app/core/services/http/paciente.service';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { ModalUrgenciaComponent } from 'src/app/modules/shared/modal-urgencia/modal-urgencia.component';
import { UsabilidadService } from 'src/app/core/services/http/usabilidad.service';
import { ModalVerMasComponent } from 'src/app/modules/enfermera/modal-ver-mas/modal-ver-mas.component';
import { timeout } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationService } from 'primeng/api';
import {MessageService} from 'primeng/api';
import * as moment from 'moment';
import { HttpClient  } from '@angular/common/http';  
//import 'moment/locale/es';
declare var JitsiMeetExternalAPI;

@Component({
  selector: 'app-list-paciente',
  templateUrl: './list-paciente.component.html',
  styleUrls: ['./list-paciente.component.scss']
})
export class ListPacienteComponent implements OnInit {
  display: boolean = false;
  pacientes: any[] = [];
  private TimerAInicio: Subscription;
  private Tiempo: number;
  Meet;
  video;
  VideoCargado;
  modalRef: BsModalRef;
  moment: any = moment;
  fechaDeAhora: Date;
  enLlamada;
  llamadaelegida;
  submitted;
  box;
  paciente;
  titulo;
  accion;
  estado;

  constructor(
    private sweetAlertService: SweetAlertService,
    private PacienteService: PacienteService,
    private modalService: ModalService,
    private ConfirmationService: ConfirmationService,
    private MessageService: MessageService, 
    private UsabilidadService: UsabilidadService,
    private http:HttpClient
  ) { }

  async ngOnInit(): Promise<void> {
    this.video = false;
    this.Tiempo = 0;
    this.llamadaelegida = null;
    this.ActTimer();
    localStorage.setItem("modalurgencia", "false");
    await this.getPacientes();
    this.fechaDeAhora = new Date();
  }

  showDialog(item) {
    this.paciente=item;
    this.submitted = false;
    this.display = true;
    this.box="";
    this.titulo="Llamado turno";
    this.accion="Llamar";
}

async llamarPaciente(){
  this.submitted=true;
  if(this.accion==="Llamar"){
    if(this.box){    
      //FALTA CREAR UNA COLUMNA NUEVA PARA EL BOX DEL CUAL SERA LLAMADO
      var resp = await this.PacienteService.actualizarBox(this.paciente.idAtencion, this.box);
      if(resp['status']){
        this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Paciente llamado correctamente', life: 3000 });
        await this.agregarUsabilidad(this.paciente.idAtencion, "Exitoso", "Llamado turno" , "Se ha llamado al paciente por su turno", "Turno: "+this.paciente.turno+", box: "+this.paciente.boxLlamado, "Proceso")
      }else{
        this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'No se pudo realizar el llamado', life: 3000 });
        await this.agregarUsabilidad(this.paciente.idAtencion, "Fallido", "Llamado turno" , "No se ha podido realizar el llamado del turno", "", "Proceso")
      }
      this.hideDialog();
    }
  }else{
    if(this.estado==1){
      //EL PACIENTE ES DERIVADO
      var resp = await this.PacienteService.RemoverPacienteAtencion(this.paciente, 5);
      if(resp['status']){
        this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Paciente derivado correctamente', life: 3000 });
        await this.agregarUsabilidad(this.paciente.idAtencion, "Exitoso", "Derivación paciente" , "El paciente ha sido derivado", "", "Proceso");
      }else{
        this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'No se pudo derivar al paciente', life: 3000 });
        await this.agregarUsabilidad(this.paciente.idAtencion, "Fallido", "Derivación paciente" , "No se ha podido realizar la derivación del paciente", "", "Proceso");
      }
      this.hideDialog();
    }else if(this.estado==2){
      //SE CANCELA EL PROCESO
      var resp = await this.PacienteService.RemoverPacienteAtencion(this.paciente, 6);
      if(resp['status']){
        this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Atencion finalizada correctamente', life: 3000 });
        await this.agregarUsabilidad(this.paciente.idAtencion, "Exitoso", "Derivación paciente" , "El paciente no se ha presentado al llamado", "", "Termino");
      }else{
        this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'No se pudo finalizar la atencion', life: 3000 });
        await this.agregarUsabilidad(this.paciente.idAtencion, "Fallido", "Derivación paciente" , "No se ha podido cancelar la consulta", "", "Proceso");
      }
      this.hideDialog();
    }
  }
}

async actualizarLlamado(paciente){
  this.paciente=paciente;
  this.submitted = false;
  this.display = true;
  this.estado="";
  this.titulo="Estado paciente";
  this.accion="Guardar";
}

hideDialog() {
  this.display = false;
  this.submitted = false;
}

  async getPacientes(): Promise<void> {
    await this.delay(2000)
    const response = await this.PacienteService.AdminiAtencion();
    if (response['status']) {
      this.pacientes = response["dataAten"]
        this.pacientes.forEach(element => {
          if (element.status != "Finalizada" && element.motivoCausa == "Urgencia") {
            var urgeact = localStorage.getItem("modalurgencia");
            if (urgeact == "false") {
              localStorage.setItem("PacienteUrgencia", JSON.stringify(element));
              localStorage.setItem("modalurgencia", "true");
              const options = { class: '', initialState: {} };
              this.modalService.openModal(ModalUrgenciaComponent, options);
            }
          }
        });
    }else{
      this.pacientes = [];
    }
    this.RevisarllamadaActiva();
  }

  async Videollamada(llamada) {
    var atenUpdate = {
      "estado": 2,
      "idAtencion": llamada.idAtencion,
    }
    var update = await this.PacienteService.ActualizarLlamada(atenUpdate);
    if(update['status']){
      this.enLlamada=true;
    //CAMBIAR ESTADO DE LA LLAMADA
    const myOverwrite = 
    {'TOOLBAR_BUTTONS': []};
    this.VideoCargado = true;
    this.llamadaelegida = llamada;
    var codigollamada = llamada.codigoLlamada;
    this.video = true;
    await this.delay(300);
    //NOMBRE
    var nombre = sessionStorage.getItem('nombreUsuario');
    //UNIRSE A LA VIDEOLLAMADA
    const domain = 'meet.jit.si';
    const options = {
      roomName: codigollamada,
      height: 250,
      configOverwrite: {
        prejoinPageEnabled: false
    },
      interfaceConfigOverwrite: myOverwrite, prejoinPageEnabled: false,
      parentNode: document.querySelector("#userlist"),
      userInfo: {
        email: 'Doctor@gmail.com',
        displayName: nombre
      }
    };
    this.Meet = new JitsiMeetExternalAPI(domain, options);
    document.getElementById("userlist").style.display = "none";
    await this.delay(6000);
    document.getElementById("userlist").style.display = "initial";
    this.VideoCargado = false;
    }else{
      await this.sweetAlertService.swalError("No ha sido posible unirse a la videollamada");
    }
  }

  async cerrarVideo(accion:number) {
    //1: CERRAR VIDEO DESDE LA ADMINISTRACION DE LA ENFERMERA
    //2:  CERRAR VIDEO PORQUE EN EL TOTEM SE CERRO
    var llamada = {
      "estado": 0,
      "idAtencion": this.llamadaelegida.idAtencion
    }
    if(accion==1){
      await this.PacienteService.ActualizarLlamada(llamada);
    }
    this.enLlamada=false;
    this.getPacientes();
    this.llamadaelegida = null;
    this.video = false;
    this.Meet.dispose();
  }

  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async RevisarllamadaActiva() {
    if (this.llamadaelegida) {
      //ESTE FILTRO ME TRAERA LA LLAMADA CUANDO LA CIERRE EL PACIENTE O FINALIZO EL PROCESO
      var resp = await this.pacientes.filter((item) => item.codigoLlamada === this.llamadaelegida.codigoLlamada && item.statusLlamada != 0);
      if (resp.length == 0) {
        this.cerrarVideo(2);
      }
    }
  }

  async ActTimer() {
    var seconds = interval(800);
    this.TimerAInicio = seconds.pipe(timeout(810)).subscribe(
      value => {
        this.Tiempo++;
        this.fechaDeAhora = new Date();
        this.getPacientes();
      },
      err => console.log(err),
    );
  }

  abrirModal(dato: string) {
    localStorage.setItem("idAtencion", dato)
    const opcion = { class: 'w-50', initialState: { pacienteV2: this.pacientes, idAtencion: dato } }
    this.modalRef = this.modalService.openModal(ModalVerMasComponent, opcion);
    this.modalRef.content.onClose.subscribe((result) => {
      if (result) {
        this.sweetAlertService.swalInfo("MODAL ABIERTO EXITOSAMENTE");
      }
    });
  }

  async agregarUsabilidad(idAtencion, resultado, accion ,info, valor, fase){
    //GENERAR LA USABILIDAD
    var usablidad = {
      "acc": "N",
      "accion": accion,
      "resultado": resultado,
      "info": info,
      "fase": fase,
      "idAtencion": idAtencion,
      "valor": valor
    }
    for (let i = 0; i < 3; i++) {
      var update = await this.UsabilidadService.agregarUsabilidad(usablidad);
      if (update["status"]) {
        i = 80;
        break;
      }
      await this.delay(1000);
    }
  }
}