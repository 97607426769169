import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private httpClient: HttpClient
  ) { }

  async getConfig(): Promise<any> {
    try {
      return await this.httpClient.get('./assets/config/config.json').toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar petición' };
    }
  }
}
