import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class UsabilidadService {
  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  });

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient
  ) { }

  //AGREGAR EL USU SI NO ESTA CREADO
  async agregarUsabilidad(usabilidad): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['api_azure']}/Usabilidad/AdministracionUsabilidad`;
    try {
      return await this.httpClient.post(url, usabilidad, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }
}
