<!-- <div class="modal-header">
    <button type="button" class="close pull-right" (click)="close()">
    <span>&times;</span>
  </button>
</div> -->
<div class="modal-body modal-lg">
    <button type="button mt-n5" class="close pull-right" (click)="close()">
  <span><i class="fas fa-times-circle"></i></span>
</button>
    <div class="row text-center">
        <div class="col-12">
            <img src="assets/img/uregencia.png">
            <h4 class="text-bluelight">EMERGENCIA</h4>
            <h5 class="text-bluelight">Módulo Atención : {{paciente.box}}
            </h5>
        </div>
    </div>
    <div class="col-12 text-center mt-3">
        <button class="btn-login" (click)="close()">Asistir</button>
    </div>



</div>