import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { PacienteService } from 'src/app/core/services/http/paciente.service';
import { timeout } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';
import { isNgTemplate } from '@angular/compiler';

@Component({
  selector: 'app-modal-urgencia',
  templateUrl: './modal-urgencia.component.html',
  styleUrls: ['./modal-urgencia.component.scss']
})
export class ModalUrgenciaComponent implements OnInit, OnDestroy {
  temporizador: Subscription;
  tiempo: number;
  paciente;
  constructor(private modalService: ModalService, private PacienteService:PacienteService) { }

  ngOnInit(): void {
    this.paciente = localStorage.getItem("PacienteUrgencia");
    this.paciente = JSON.parse(this.paciente);
    this.tiempo=0;
    this.timer();
  }

  ngOnDestroy(){
    this.temporizador.unsubscribe();
    this.tiempo=0;
  }

  async close() {
    localStorage.setItem("modalurgencia","false");
    await this.PacienteService.RemoverPacienteAtencion(this.paciente, 2);
    this.modalService.closeModal();
  }

  async timer(){
    var aux: number=0;
    var seconds = interval(1000);
    this.temporizador = seconds.pipe(timeout(3100)).subscribe(
      async (result) => {
        this.tiempo++;
        var pacientes = await this.PacienteService.AdminiAtencion();
        pacientes=pacientes["dataAten"]
        var filtro = await pacientes.filter((paciente) => paciente.idAtencion == this.paciente.idAtencion && paciente.status !=="Finalizada")
        if(filtro.length == 0){
          localStorage.setItem("modalurgencia","false");
          this.modalService.closeModal();
        }
      })
  }
}
