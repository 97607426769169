import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ValidatorsService } from 'src/app/core/services/miscellaneous/validators.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { FormControl } from '@angular/forms';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/core/services/http/Usuario.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Output() changeStep = new EventEmitter<any>();
  @Input() title: string;
  user = new FormControl('');
  pass = new FormControl('');
  rutValid: any;
  disabled = false;
  loading = false;

  constructor(
    private sweetAlertService: SweetAlertService,
    private validatorsService: ValidatorsService,
    private AuthService: AuthService,
    private router: Router,
    private UsuarioService: UsuarioService
  ) { }

  ngOnInit(): void {

  }

  validaRut(): void {
    this.rutValid = this.validatorsService.validarRut(this.user.value.replace(/\./g, '').replace('-', ''));
    this.user.setValue(this.validatorsService.formateaRut(this.user.value));

    if (this.user.value.length > 10 && this.rutValid) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }

  async onSubmit(user: string, pass: string): Promise<any> {
    this.loading = true;
    this.user.disable();
    this.pass.disable();
    const resp = await this.AuthService.login(user, pass);

    if (resp['status'] && resp['rol']==2) {
      sessionStorage.setItem('autenticado', 'true')
      sessionStorage.setItem('nombreUsuario', resp['nombre'])
      sessionStorage.setItem('rolUsuario', resp['rol'])
      sessionStorage.setItem('rutUsuario', user)
      //ACTUALIZAR ESTADO DE LA ENFERMERA PAR QUE APAREZCA CONECTADA
      var enviar = {
        "acc": "U",
        "rutUsuario": user,
        "nombre": resp['nombre'],
        "clave": "",
        "estado": true,
        "rol" : +resp['rol'],
        "actividad": true
      }
      var status =await this.UsuarioService.cambiarEstadoMedico(enviar);
      if(status['status']){
        //REDIRECCIONAR
        this.loading = false;
        this.router.navigate(['/personal']);
      }else{
        const message = 'No se pudo conectar con el servidor';
        await this.sweetAlertService.swalError(message);
        this.loading=false;
        this.AuthService.logout();
      }
      
    }else if(resp['status'] && resp['rol']!=2){
      const message = 'No posee permisos para acceder a este sitio';
      this.sweetAlertService.swalError(message);
      this.loading=false;
    } else {
      const message = 'Usuario o contraseña incorrecta';
        this.sweetAlertService.swalError(message);
        this.loading=false;
    }

    this.user.enable();
    this.pass.enable();
  }

}
