import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
    headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    });
  
    constructor(
      private configService: ConfigService,
      private httpClient: HttpClient
    ) {}
  
    //VERIFICAR QUE HAYAN ENFERMERAS DISPONIBLES
    async TraerListaUsuario(): Promise<any> {
      const API_URL = await this.configService.getConfig();
      const url = `${API_URL['api_azure']}/Usuario/TraerListaUsuario`;
  
      try {
        return await this.httpClient.get(url, { headers: this.headers }).toPromise();
      } catch (error) {
        return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
      }
    }

    //ALTERNAR ENTRE DISPONIBLE Y NO DISPONIBLE
    async cambiarEstadoMedico(usuario): Promise<any> {
      const API_URL = await this.configService.getConfig();
      const url = `${API_URL['api_azure']}/Usuario/AdministracionUsuario`;
      try {
        return await this.httpClient.post(url,usuario, { headers: this.headers }).toPromise();
      } catch (error) {
        return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
      }
    }
  
  }
  