<app-header></app-header>
<div *ngIf="enLlamada">
    <div class="row">
        <div *ngIf="video" class="col-3">
            <div class="btn btn-danger mt-2" (click)="cerrarVideo(1)"> Cerrar </div>
        
            <div class="pantallacarga" *ngIf="VideoCargado">
                <h4 class="text-bluelight15" style="margin-top: 75px">Conectando con el Paciente<br>
                    favor espere un
                    momento</h4>
                <img src="assets/img/carga.gif">
            </div>
        
            <div id="userlist" class="mt-3" style=" height: 100%;  display: block;   justify-content: center; top: 8%;"></div>
        </div>
        <div class="col-9 table table-striped table-sm"
        width="100%">
            <table class="table table-hover">
                <thead>
                    <tr class="text-bluelight">
                        <th scope="col">RUT</th>
                        <th scope="col">Sexo</th>
                        <th scope="col">Edad</th>
                        <th scope="col">Motivo consulta</th>
                        <th scope="col">Signos vitales</th>
                        <th scope="col">Zona(s) Afectada(s)</th>
                        <th scope="col">Dolor</th>
                        <th scope="col">Tiempo de espera</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Tótem</th>
                        <th scope="col">Derivaciones</th>
                    </tr>
                </thead>
            
                <tbody>
                    <tr *ngIf="1>pacientes.length">
                        <td colspan="12" class="text-center text-bluelight">No hay consultas pendientes</td>
                    </tr>
                    <tr *ngFor="let item of pacientes, index as index ">
                        <!--RUT PACIENTE-->
                        <td [ngClass]="{'noTomado': item.rutPaciente==='No Tomado', 'text-bluelight': item.rutPaciente!=='No Tomado'}">
                            {{ item.rutPaciente }}
                        </td>
                        <!--SEXO/GENERO-->
                        <td [ngClass]="{'noTomado': item.genero==='No Tomado', 'text-bluelight': item.genero!=='No Tomado'}">{{
                            item.genero }}
                        </td>
                         <!--EDAD-->
                         <td class="text-bluelight" *ngIf="item.edad>=0">
                            {{ item.edad }} Año(s)
                        </td>
                        <td class="noTomado" *ngIf="item.edad<0">
                            No Tomada
                        </td>
                        <!--MOTIVO DE LA CAUSA-->
                        <td class="videoColor1 animated bounceIn text-center" *ngIf="item.motivoCausa === 'Videollamada' && item.status !=='En proceso'">
                            <a (click)="Videollamada(item)">
                                {{ item.motivoCausa }}
                            </a>
                        </td>
                        <td [ngClass]="{'alterado':item.motivoCausa==='No Tomado','text-bluelight':item.motivoCausa!=='No Tomado'}" *ngIf="item.status === 'En proceso' || item.motivoCausa !== 'Videollamada'">
                            {{ item.motivoCausa }}
                        </td>
                        <!--SIGNOS VITALES-->
                        <td>
                            <!----------------------------------TEMPERATURA--------------------------------->
                            <div class="d-flex" step="any">
            
                                <div step="any" *ngIf="item.temperatura>0">
                                    <div
                                        [ngClass]="{'alterado': item.temperatura<35 || item.temperatura>37.5,'text-bluelight': item.temperatura<=37.5 && item.temperatura>=35}">
                                        <img class="ico-list" src="assets/img/temperatura-c.png" title="Temperatura">
                                        {{item.temperatura}}°C
                                    </div>
                                </div>
                                <div step="any" *ngIf="item.temperatura==0">
                                    <div class="alterado">
                                        <img class="ico-list" src="assets/img/temperatura-c.png" title="Temperatura">
                                        N/A
                                    </div>
                                </div>
                                <div class="text-grey ml-1 mr-1">|</div>
                                <!-------------------------------PRESIÓN--------------------------------->
                                <div
                                    *ngIf="item.presionMax>0 && item.presionMin>0">
                                    <div [ngClass]="{'alterado' : item.presionMin<60 || item.presionMin>90 || item.presionMax>130 || item.presionMax<90,'text-bluelight' : item.presionMin>=60 && item.presionMin<=90 && item.presionMax>=90 && item.presionMax<=130}">
                                        <img class="ico-list" src="assets/img/pulso.png" title="Presión">
                                        {{item.presionMax}}-{{item.presionMin}} mmHg
                                    </div>
                                </div>
                                <!--NO TOMADA-->
                                <div *ngIf="item.presionMax == 0 || item.presionMin == 0">
                                    <div class="alterado">
                                        <img class="ico-list" src="assets/img/pulso.png" title="Presión">
                                        N/A
                                    </div>
                                </div>
                                <!-------------------------------PULSO-------------------------------->
                                <div class="text-grey ml-1 mr-1">|</div>
                                <div *ngIf="item.pulso>0">
                                    <div
                                        [ngClass]="{'alterado' : item.pulso>100 || item.pulso<60,'text-bluelight': item.pulso>=60 && item.pulso<=100}">
                                        <img class="ico-list" src="assets/img/pulso-1.png" title="Pulso">
                                        {{item.pulso}} lpm
                                    </div>
                                </div>
                                <div *ngIf="item.pulso==0">
                                    <div class="alterado">
                                        <img class="ico-list" src="assets/img/pulso-1.png" title="Pulso">
                                        N/A
                                    </div>
                                </div>
                                <div class="text-grey ml-1 mr-1">|</div>
                                <!-------------------------------OXÍMETRO-------------------------------->
                                <div *ngIf="item.oximetro==0">
                                    <div class="alterado">
                                        <img class="ico-list" src="assets/img/sangre.png" title="Oxímetro">
                                        N/A
                                    </div>
                                </div>
                                <div *ngIf="item.oximetro>0">
                                        <div [ngClass]="{'alterado' : item.oximetro<90,'text-bluelight' : item.oximetro>= 90}">
                                            <img class="ico-list" src="assets/img/sangre.png" title="Oxímetro">
                                            {{item.oximetro}}%
                                        </div>
                                </div>
                            </div>
                        </td>
                        <!--FIN SIGNOS VITALES-->
                        <!--VER ZONAS AFECTADAS-->
                        <td>
                            <div *ngIf="item.idZona!=='No Tomado'">
                                <a href="#" (click)="abrirModal(item.idAtencion)">
                                    Ver
                                </a>
                            </div>
                            <div *ngIf="item.idZona==='No Tomado'" class="alterado">
                                No Tomadas
                            </div>
                        </td>
                        <!--NIVEL DE DOLOR-->
                        <td [ngClass]="{'noTomado': item.nivel==='No Tomado', 'text-bluelight': item.nivel!=='No Tomado'}">{{
                            item.nivel }}
                        </td>
                        <!--TIEMPO TRANSCURRIDO-->
                        <td class="text-bluelight">
                             <!-- {{moment(moment(fechaDeAhora,'YYYY-MM-DD HH:mm:ss')-moment(item.hora,'YYYY-MM-DD
                             HH:mm:ss')).format('HH:mm:ss')}} -->
                             {{moment(moment(fechaDeAhora,'YYYY-MM-DD HH:mm:ss').diff(moment(item.hora,'YYYY-MM-DD
                            HH:mm:ss'))).add('hours', 3) | date:'HH:mm:ss'}}
            
                        </td>
                        <!--ESTADO DEL PROCESO-->
                        <td class="videoColor1 animated text-center" *ngIf="item.status==='Llamado turno'">
                           <a (click)="actualizarLlamado(item)">{{item.status}}</a> 
                        </td>
                        <td class="text-bluelight" *ngIf="item.status!=='Llamado turno'">
                            {{item.status}}
                        </td>
                        <!--TOTEM EN EL QUE SE ESTA EJECUTANDO EL PROCESO-->
                        <td class="text-bluelight">
                            {{item.box}}
                        </td>
                        <!--DERIVACIONES-->
                        <td>
                            <div class="d-flex ">
                                <div class="btn btn-danger circle-btn" (click)="showDialog(item)">C1</div>
                                <div class="btn btn-orange circle-btn" (click)="showDialog(item)">C2</div>
                                <div class="btn btn-warning circle-btn" (click)="showDialog(item)">C3</div>
                                <div class="btn btn-success circle-btn" (click)="showDialog(item)">C4</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            
            </table>
        </div>
    </div>
</div>
<div *ngIf="!enLlamada">
    <div class="row">
        <table class="table table-hover ml-2 mr-2">
            <thead>
                <tr class="text-bluelight">
                    <th scope="col">RUT</th>
                    <th scope="col">Sexo</th>
                    <th scope="col">Edad</th>
                    <th scope="col">Motivo consulta</th>
                    <th scope="col">Signos vitales</th>
                    <th scope="col">Zona(s) Afectada(s)</th>
                    <th scope="col">Dolor</th>
                    <th scope="col">Tiempo de espera</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Tótem</th>
                    <th scope="col">Derivaciones</th>
                </tr>
            </thead>
        
            <tbody>
                <tr *ngIf="1>pacientes.length">
                    <td colspan="12" class="text-center text-bluelight">No hay consultas pendientes</td>
                </tr>
                <tr *ngFor="let item of pacientes, index as index ">
                    <!--RUT PACIENTE-->
                    <td [ngClass]="{'noTomado': item.rutPaciente==='No Tomado', 'text-bluelight': item.rutPaciente!=='No Tomado'}">
                        {{ item.rutPaciente }}
                    </td>
                    <!--SEXO/GENERO-->
                    <td [ngClass]="{'noTomado': item.genero==='No Tomado', 'text-bluelight': item.genero!=='No Tomado'}">{{
                        item.genero }}
                    </td>
                     <!--EDAD-->
                     <td class="text-bluelight" *ngIf="item.edad>=0">
                        {{ item.edad }} Año(s)
                    </td>
                    <td class="noTomado" *ngIf="item.edad<0">
                        No Tomada
                    </td>
                    <!--MOTIVO DE LA CAUSA-->
                    <td class="videoColor1 animated bounceIn text-center" *ngIf="item.statusLlamada == 1">
                        <a (click)="Videollamada(item)">
                            Videollamada
                        </a>
                    </td>
                    <td [ngClass]="{'alterado':item.motivoCausa==='No Tomado','text-bluelight':item.motivoCausa!=='No Tomado'}" *ngIf="item.statusLlamada !== 1">
                        {{ item.motivoCausa }}
                    </td>
                    <!--SIGNOS VITALES-->
                    <td>
                        <!----------------------------------TEMPERATURA--------------------------------->
                        <div class="d-flex" step="any">
        
                            <div step="any" *ngIf="item.temperatura>0">
                                <div
                                    [ngClass]="{'alterado': item.temperatura<35 || item.temperatura>37.5,'text-bluelight': item.temperatura<=37.5 && item.temperatura>=35}">
                                    <img class="ico-list" src="assets/img/temperatura-c.png" title="Temperatura">
                                    {{item.temperatura}}°C
                                </div>
                            </div>
                            <div step="any" *ngIf="item.temperatura==0">
                                <div class="alterado">
                                    <img class="ico-list" src="assets/img/temperatura-c.png" title="Temperatura">
                                    N/A
                                </div>
                            </div>
                            <div class="text-grey ml-1 mr-1">|</div>
                            <!-------------------------------PRESIÓN--------------------------------->
                            <div
                                *ngIf="item.presionMax>0 && item.presionMin>0">
                                <div [ngClass]="{'alterado' : item.presionMin<60 || item.presionMin>90 || item.presionMax>130 || item.presionMax<90,'text-bluelight' : item.presionMin>=60 && item.presionMin<=90 && item.presionMax>=90 && item.presionMax<=130}">
                                    <img class="ico-list" src="assets/img/pulso.png" title="Presión">
                                    {{item.presionMax}}-{{item.presionMin}} mmHg
                                </div>
                            </div>
                            <!--NO TOMADA-->
                            <div *ngIf="item.presionMax == 0 || item.presionMin == 0">
                                <div class="alterado">
                                    <img class="ico-list" src="assets/img/pulso.png" title="Presión">
                                    N/A
                                </div>
                            </div>
                            <!-------------------------------PULSO-------------------------------->
                            <div class="text-grey ml-1 mr-1">|</div>
                            <div *ngIf="item.pulso>0">
                                <div
                                    [ngClass]="{'alterado' : item.pulso>100 || item.pulso<60,'text-bluelight': item.pulso>=60 && item.pulso<=100}">
                                    <img class="ico-list" src="assets/img/pulso-1.png" title="Pulso">
                                    {{item.pulso}} lpm
                                </div>
                            </div>
                            <div *ngIf="item.pulso==0">
                                <div class="alterado">
                                    <img class="ico-list" src="assets/img/pulso-1.png" title="Pulso">
                                    N/A
                                </div>
                            </div>
                            <div class="text-grey ml-1 mr-1">|</div>
                            <!-------------------------------OXÍMETRO-------------------------------->
                            <div *ngIf="item.oximetro==0">
                                <div class="alterado">
                                    <img class="ico-list" src="assets/img/sangre.png" title="Oxímetro">
                                    N/A
                                </div>
                            </div>
                            <div *ngIf="item.oximetro>0">
                                    <div [ngClass]="{'alterado' : item.oximetro<90,'text-bluelight' : item.oximetro>= 90}">
                                        <img class="ico-list" src="assets/img/sangre.png" title="Oxímetro">
                                        {{item.oximetro}}%
                                    </div>
                            </div>
                        </div>
                    </td>
                    <!--FIN SIGNOS VITALES-->
                    <!--VER ZONAS AFECTADAS-->
                    <td>
                        <div *ngIf="item.idZona!=='No Tomado'">
                            <a href="#" (click)="abrirModal(item.idAtencion)">
                                Ver
                            </a>
                        </div>
                        <div *ngIf="item.idZona==='No Tomado'" class="alterado">
                            No Tomadas
                        </div>
                    </td>
                    <!--NIVEL DE DOLOR-->
                    <td [ngClass]="{'noTomado': item.nivel==='No Tomado', 'text-bluelight': item.nivel!=='No Tomado'}">{{
                        item.nivel }}
                    </td>
                    <!--TIEMPO TRANSCURRIDO-->
                    <td class="text-bluelight">
                         <!-- {{moment(moment(fechaDeAhora,'YYYY-MM-DD HH:mm:ss')-moment(item.hora,'YYYY-MM-DD
                         HH:mm:ss')).format('HH:mm:ss')}} -->
                         {{moment(moment(fechaDeAhora,'YYYY-MM-DD HH:mm:ss').diff(moment(item.hora,'YYYY-MM-DD
                        HH:mm:ss'))).add('hours', 3) | date:'HH:mm:ss'}}
        
                    </td>
                    <!--ESTADO DEL PROCESO-->
                    <td class="videoColor1 animated text-center" *ngIf="item.status==='Llamado turno'">
                       <a (click)="actualizarLlamado(item)">{{item.status}}</a> 
                    </td>
                    <td class="text-bluelight" *ngIf="item.status!=='Llamado turno'">
                        {{item.status}}
                    </td>
                    <!--TOTEM EN EL QUE SE ESTA EJECUTANDO EL PROCESO-->
                    <td class="text-bluelight">
                        {{item.box}}
                    </td>
                    <!--DERIVACIONES-->
                    <td>
                        <div class="d-flex ">
                            <div class="btn btn-danger circle-btn" (click)="showDialog(item)">C1</div>
                            <div class="btn btn-orange circle-btn" (click)="showDialog(item)">C2</div>
                            <div class="btn btn-warning circle-btn" (click)="showDialog(item)">C3</div>
                            <div class="btn btn-success circle-btn" (click)="showDialog(item)">C4</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        
        </table>

    </div>
</div>




<p-dialog header="Title" [(visible)]="display" [style]="{width: '50vw'}" [header]="titulo" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="p-field" *ngIf="accion==='Llamar'">
            <label for="box">Box</label>
            <select class="form-control" required autofocus [(ngModel)]="box">
                <option value="">Seleccione un box</option>
                <option value="01">Box 1</option>
                <option value="02">Box 2</option>
            </select>
            <small class="p-invalid" *ngIf="submitted && !box">Por favor seleccione un box</small>
        </div>
        <div class="p-field" *ngIf="accion!=='Llamar'">
            <label for="estado">¿El paciente se presento al llamado?</label>
            <select class="form-control" required autofocus [(ngModel)]="estado">
                <option value="">Seleccione una opción</option>
                <option value="1">SI</option>
                <option value="2">NO</option>
            </select>
            <small class="p-invalid" *ngIf="submitted && !estado">Por favor seleccione una opción</small>
        </div>
        <br>
        <button class="btn btn-primary" (click)="llamarPaciente()">{{accion}}</button>
    </ng-template>
</p-dialog>