<header class="shadow"> 
    <div class="row">
        <div class="col-8 offset-2">
            <div class="text-center pt-2 pb-2 text-bluelight">
                <img src="assets/img/logo.svg" style="height: 40px;">
            </div>
        </div>
        <div class="col-2 text-right">

                <a type="button" style="margin-top: 10px; margin-right: 10px; text-decoration: none;" class="dropdown-toggle botonCerrar" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img style="width: 25px; height: 25px;" [src]="icono">
                        {{nombreUsuario}}
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <h6 class="dropdown-header">Estado</h6>
                  <button class="dropdown-item" style="color: green;" (click)="cambiarEstado(1)">Disponible</button>
                  <button class="dropdown-item" style="color: red;" (click)="cambiarEstado(0)">No disponible</button>
                  <div class="dropdown-divider"></div>
                  <button class="dropdown-item" (click)="exit($event)" type="button">Cerrar sesión</button>
                </div>
        </div>
    </div>
</header>
<p-toast position="top-left"></p-toast>
