import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private token: string;
    headers: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    });

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient,
        private Router: Router
    ) { }

    async login(rut, password): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_azure']}/Usuario/Login`;
        var datos={
          "rut":rut,
          "clave":password
        }
        try {
            return await this.httpClient.post(url, datos,{ headers: this.headers }).toPromise();
            // return {
            //     status: true,
            //     code: 200,
            //     data: {}
            // }
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    logout() {
        this.token = '';
        sessionStorage.removeItem('token_api_usabilidad');
        sessionStorage.removeItem('expToken_api_usabilidad');
        sessionStorage.removeItem('refreshToken_api_usabilidad');
        sessionStorage.removeItem('autenticado');
        sessionStorage.removeItem('nombreUsuario')
        sessionStorage.removeItem('rutUsuario');
        sessionStorage.removeItem('rolUsuario');
        this.Router.navigate(['/login']);
      }

    leerToken(): void {
        if (sessionStorage.getItem('token_api_usabilidad') && sessionStorage.getItem('refreshToken_api_usabilidad')) {
          this.token = sessionStorage.getItem('token_api_usabilidad');
        } else {
          this.token = '';
        }
      }

    estaAutenticado(): boolean {
        return sessionStorage.getItem('autenticado') === 'true' ? true : false;
      }

      // Almacena en el sessionStorage el token
  guardarTokenStorage(token: string): void {
    this.token = token;
    sessionStorage.setItem('token_api_usabilidad', token);
  }
}
