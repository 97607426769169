import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { UsuarioService } from 'src/app/core/services/http/Usuario.service';
import { PacienteService } from 'src/app/core/services/http/paciente.service';

@Component({
  selector: 'app-modal-derivar',
  templateUrl: './modal-derivar.component.html',
  styleUrls: ['./modal-derivar.component.scss']
})
export class ModalDerivarComponent implements OnInit {
  doctors;
  doc = new FormControl('');
  docselect;

  constructor(
    private modalService: ModalService,
    private sweetAlertService: SweetAlertService,
    private UsuarioService:UsuarioService,
    private PacienteService:PacienteService
  ) { }

  ngOnInit(): void {
    this.getDoctor();
  }

  doctorselec(i){
    this.docselect = this.doctors[i];
  }

  async getDoctor(): Promise<void> {
    var resp = await this.UsuarioService.TraerListaUsuario();
    this.doctors = resp["usuarios"];
  }

  onSubmit(): void { 
    var paciente = localStorage.getItem("PacienteVideo");
    paciente = JSON.parse(paciente);
    paciente["status"] = "Videollamada";
    paciente["docRut"] = this.doc.value.rut;
    var resp = this.PacienteService.ActualizarPAcienteAlista(paciente);
    this.close();
  }

  close(): void {
    this.modalService.closeModal();
  }

}
