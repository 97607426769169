import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class PacienteService {
  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  });

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient
  ) { }


  async ActualizarPAcienteAlista(paciente): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['api_azure']}/Paciente/ActualizarPAcienteAlista`;

    try {
      return await this.httpClient.post(url, paciente, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async ActualizarPaciente(atencion): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['api_azure']}/Atencion/AdministracionAtencion`;
    
    try {
      return await this.httpClient.post(url, atencion, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }
  //FILTRA LAS ATENCIONES POR ID DE OFICINA/SUCURSAL
  async AdminiAtencion(): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const idAtencion = `${API_URL['numero_oficina']}`;
    const url = `${API_URL['api_azure']}/Atencion/AtencionPorOficina?IdOficina=`+idAtencion;

    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async partesCuerpo(id: number): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['api_azure']}/Zona/PartesCuerpo`;
    //
    var lista = {
      "idAtencion": id
    }
  
    try {
      return await this.httpClient.post(url, lista, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }


  async RemoverPacienteAtencion(paciente, status: number): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['api_azure']}/Atencion/UpdateEstado`;
    var params = {
      "tipo": status,
      "idAtencion": paciente.idAtencion
    }

    try {
      return await this.httpClient.post(url, params, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }
  //ACTUALIZAR BOX
  async actualizarBox(idAtencion: number, box: string): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['api_azure']}/Atencion/UpdateBox`;
    var params = {
      "idAtencion": idAtencion,
      "boxLlamado": box
    }
    try {
      return await this.httpClient.post(url, params, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async ActualizarLlamada(atencion): Promise<any> {
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['api_azure']}/Atencion/UpdateLlamada`;
    try {
      return await this.httpClient.post(url, atencion, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }
  
}
