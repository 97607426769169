import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
  });

  async swalError(text: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      icon: 'error',
      title: '¡Error!',
      text,
      timer: 20000,
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
      }
    }).then(async (result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        //TEMPORIZADOR
      } else if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async errorToast(message: string): Promise<void> {
    await this.toast.fire({
      icon: 'error',
      title: message
    });
  }

  swalClose(): void {
    Swal.close();
  }

  swalErrorService(text: string): void {
    Swal.fire({
      icon: 'error',
      title: 'Lo sentimos!',
      text,
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-exit',
      }
    }).then((result) => {
      if (result.value) {
        localStorage.clear();
        window.location.href = `${window.location.origin}/admin-front`;
      }
    });
  }

  swalLoading(texto): void {
     Swal.fire({
      title: texto,
      imageUrl: 'assets/img/carga.gif',
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(255,255,255,0.4)',
      allowOutsideClick: false
    });
  }

  async swalInfo(message: string) {
    Swal.fire({
      title: message,
      confirmButtonColor: '#00AA9F',
    });
  }

}
