import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import {UsuarioService} from 'src/app/core/services/http/Usuario.service';
import { ConfirmationService } from 'primeng/api';
import {MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  nombreUsuario;
  icono;
  rut;
  rol;

  constructor(
    private AuthService: AuthService,
    private UsuarioService: UsuarioService,
    private ConfirmationService: ConfirmationService,
    private MessageService: MessageService,
    private primengConfig: PrimeNGConfig,
  ) {}

  ngOnInit(): void {
    this.nombreUsuario = sessionStorage.getItem("nombreUsuario");
    this.rut = sessionStorage.getItem("rutUsuario");
    this.rol = sessionStorage.getItem("rolUsuario");
    this.icono="assets/img/profile-enable.png";
  }

  async cambiarEstado(estado: number){
    var act: boolean;
    if(estado==1){
      act=true;
    }else{
      act=false;
    }
    var enviar = {
      "acc": "U",
      "rutUsuario": this.rut,
      "nombre": this.nombreUsuario,
      "clave": "",
      "estado": null,
      "rol" : +this.rol,
      "actividad": act
    }
    
    var status =await this.UsuarioService.cambiarEstadoMedico(enviar);
    if(status['status']){
      if(estado===1){
        this.icono="assets/img/profile-enable.png";
      }else{
        this.icono="assets/img/profile-disabled.png";
      }
      this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Estado actualizado correctamente', life: 3000 });
    }else{
      this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'El estado no se pudo actualizar', life: 3000 });
    }
   
  }

  async exit(e: any)  {
    //ACTUALIZAR ESTADO
    var enviar = {
      "acc": "U",
      "rutUsuario": this.rut,
      "nombre": this.nombreUsuario,
      "clave": "",
      "estado": true,
      "rol" : +this.rol,
      "actividad": false
    }
    var status =await this.UsuarioService.cambiarEstadoMedico(enviar);
    if(status['status']){
      this.AuthService.logout();
  }else{
    this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'No se ha podido cerrar su sesión', life: 3000 });
  }
  }
}
