<!-- <div class="modal-header">
    <button type="button" class="close pull-right" (click)="close()">
    <span>&times;</span>
  </button>
</div> -->
<div class="modal-body modal-lg">
    
    <table class="table">
        <thead>
            <th style="color: black;">
                Zona Afectada
            </th>
            <th class="text-right">
                <button class="btn btn-primary botonCerrar" (click)="close()">
                    <span>X</span>
                </button>
            </th>
        </thead>
        <tbody>
            <tr *ngIf="!zonasCuerpo">
                <h5>No hay zonas afectadas</h5>
            </tr>
            <tr *ngFor="let item of zonasCuerpo">
                <td colspan="2" class="text-bluelight">{{ item.lugar }}</td>
            </tr>
        </tbody>
    </table>
    
    
</div>